import ApiService from './api.service';

const ProjectService = {
  async getAllProjects() {
    const requestData = {
      url: '/api/projects',
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getGroupsWithProjects({ filters }) {
    const requestData = {
      url: '/api/groups',
      method: 'get',
      params: {
        executors: filters.executors || null,
        owners: filters.owners || null,
        tags: filters.tags || null,
        searchQuery: filters.searchQuery || null,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getProject(projectId) {
    const requestData = {
      url: `/api/projects/${projectId}`,
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getAllTasks(payload) {
    const { filters } = payload;
    const requestData = {
      url: '/api/projects/tasks',
      method: 'get',
      params: {
        executors: filters.executors || null,
        owners: filters.owners || null,
        tags: filters.tags || null,
        archive: false,
        timeEstimate: filters.timeEstimate || null,
        searchQuery: filters.searchQuery || null,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getAllProjectTasks(payload) {
    const { filters } = payload;
    const requestData = {
      url: '/api/projects/tasks/project',
      method: 'get',
      params: {
        executors: filters.executors || null,
        owners: filters.owners || null,
        tags: filters.tags || null,
        archive: false,
        timeEstimate: filters.timeEstimate || null,
        searchQuery: filters.searchQuery || null,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getAllSupportTasks(payload) {
    const { filters } = payload;
    const requestData = {
      url: '/api/projects/tasks/support',
      method: 'get',
      params: {
        executors: filters.executors || null,
        owners: filters.owners || null,
        tags: filters.tags || null,
        archive: false,
        searchQuery: filters.searchQuery || null,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getProjectTasks(payload) {
    const { id, filters } = payload;
    const requestData = {
      url: `/api/projects/${id}/tasks`,
      method: 'get',
      params: {
        executors: filters.executors || null,
        owners: filters.owners || null,
        tags: filters.tags || null,
        archive: filters.archive || false,
        timeEstimate: filters.timeEstimate || false,
        searchQuery: filters.searchQuery || null,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getStatuses() {
    const requestData = {
      url: '/api/statuses',
      method: 'get',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async updateDescription(projectId, description) {
    const requestData = {
      url: `/api/projects/${projectId}`,
      method: 'put',
      data: {
        description,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async createCredential(projectId, credential) {
    const requestData = {
      url: '/api/credential/create',
      method: 'post',
      data: {
        projectId,
        credential,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateCredential(credential) {
    const requestData = {
      url: '/api/credential/update',
      method: 'post',
      data: {
        credential,
      },
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteCredential(credentialId) {
    const requestData = {
      url: `/api/credential/delete/${credentialId}`,
      method: 'delete',
    };
    try {
      return ApiService.request(requestData);
    } catch (e) {
      console.log(e);
      return false;
    }
  },

};

export default ProjectService;